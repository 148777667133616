import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { Button, CircularProgress, Alert, Box, Typography, TextField } from '@mui/material';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ onSuccess, onError, onClose, user }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!user || !user.id) {
      setError('Usuario no válido. Por favor, inicia sesión nuevamente.');
    } else {
      setError(null);
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);

    if (!stripe || !elements) {
      setError('Stripe no se ha inicializado correctamente.');
      setProcessing(false);
      return;
    }

    if (!user || !user.id) {
      setError('Usuario no válido. Por favor, inicia sesión nuevamente.');
      setProcessing(false);
      return;
    }

    if (!name || !email) {
      setError('Por favor, completa tu nombre y correo electrónico.');
      setProcessing(false);
      return;
    }

    try {
      // Crear la suscripción en lugar de una intención de pago única
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/create-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          userId: user.id,
          customerInfo: { name, email }
        }),
      });

      if (!response.ok) {
        const errorData = await response.text();
        throw new Error(`Error del servidor: ${errorData}`);
      }

      const data = await response.json();
      if (!data.clientSecret) {
        throw new Error('No se recibió el client secret del servidor.');
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error('No se pudo obtener el elemento de tarjeta.');
      }

      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(data.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: { name, email },
        },
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      if (paymentIntent.status === 'succeeded') {
        onSuccess(paymentIntent);
      } else {
        throw new Error(`Estado del pago inesperado: ${paymentIntent.status}`);
      }
    } catch (err) {
      setError(err.message || 'Error desconocido.');
      if (typeof onError === 'function') {
        onError(err);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Nombre Completo"
        type="text"
        required
        fullWidth
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      
      <TextField
        label="Correo Electrónico"
        type="email"
        required
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          Información de la tarjeta
        </Typography>
        <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770' } } }} />
      </Box>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Button
        type="submit"
        variant="contained"
        fullWidth
        disabled={!stripe || processing}
        sx={{ mb: 2 }}
      >
        {processing ? <CircularProgress size={24} /> : 'SUSCRIBIRSE POR $5/MES'}
      </Button>
      <Button onClick={onClose} variant="outlined" fullWidth>
        CANCELAR
      </Button>
    </form>
  );
};

export default function StripePayment({ onSuccess, onError, onClose, user }) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm onSuccess={onSuccess} onError={onError} onClose={onClose} user={user} />
    </Elements>
  );
}