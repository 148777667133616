import React, { useState } from 'react';
import {
  Button,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Paper,
  Alert,
} from '@mui/material';
import {
  Star as StarIcon,
  Check as CheckIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import StripePayment from './StripePayment';

export default function PremiumSubscription({ onUpgrade, onClose, user }) {
  const [showStripePayment, setShowStripePayment] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleSubscribe = () => {
    setShowStripePayment(true);
  };

  const handlePaymentSuccess = () => {
    setShowStripePayment(false);
    setSuccessMessage('Felicidades, ya eres un usuario Premium');
    onUpgrade();  // Actualiza el estado del usuario a premium
  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 400, mx: 'auto', mt: 4, p: 3, position: 'relative' }}>
      <IconButton 
        onClick={onClose} 
        sx={{ position: 'absolute', top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>

      <Typography variant="h5" sx={{ mb: 2 }}>
        Suscripción Premium Mensual
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        Obtén acceso a funciones exclusivas con una suscripción mensual de solo $5:
      </Typography>
      <List>
        {['Generación de Ideas Mejorada', 'Generación de Ideas Ilimitadas','Guarda Tus Ideas Favoritas'].map((feature) => (
          <ListItem key={feature}>
            <ListItemIcon><CheckIcon /></ListItemIcon>
            <ListItemText primary={feature} />
          </ListItem>
        ))}
      </List>

      {successMessage && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {successMessage}
        </Alert>
      )}

      <Button 
        variant="contained" 
        startIcon={<StarIcon />} 
        fullWidth 
        onClick={handleSubscribe}
      >
        SUSCRIBIRSE POR $5/MES
      </Button>

      {showStripePayment && (
        <StripePayment
          amount={500} // Representa $5 en centavos
          onSuccess={handlePaymentSuccess}
          user={user}
          onClose={() => setShowStripePayment(false)}
        />
      )}
    </Paper>
  );
}