import React, { useState } from 'react';
import {
  Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, MenuItem, ListItemIcon, ListItemText
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    padding: theme.spacing(2),
  },
}));

export default function CancelSubscription({ user, onClose }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [remainingDays, setRemainingDays] = useState(null);

  const handleOpen = () => {
    setOpen(true);
    fetchRemainingDays();
  };

  const handleClose = () => {
    setOpen(false);
    setError(null);
    setRemainingDays(null);
    if (onClose) onClose(); // Cierra el diálogo si se pasa la prop onClose
  };

  const fetchRemainingDays = async () => {
    if (!user) {
      setError("Usuario no válido. Inicie sesión nuevamente.");
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscription-remaining?userId=${user.id}`);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al obtener los días restantes de la suscripción');
      }
      const data = await response.json();
      setRemainingDays(data.remainingDays);
    } catch (error) {
      setError(error.message || 'Error desconocido al obtener los días restantes de la suscripción');
    }
  };

  const handleCancelSubscription = async () => {
    if (!user) {
      setError("Usuario no válido. Inicie sesión nuevamente.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/cancel-subscription`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.id }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error al cancelar la suscripción');
      }

      handleClose();
      window.location.reload(); // Recargar para reflejar el cambio
    } catch (error) {
      setError(error.message || 'Error desconocido al cancelar la suscripción');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <MenuItem onClick={handleOpen}>
        <ListItemIcon>
          <CloseIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Cancelar Suscripción" />
      </MenuItem>

      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle>Cancelar Suscripción</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {remainingDays !== null 
              ? `Actualmente tienes ${remainingDays} día(s) restantes de tu suscripción.`
              : 'Cargando información de la suscripción...'}
          </DialogContentText>
          <DialogContentText>
            ¿Estás seguro de que deseas cancelar tu suscripción? Perderás acceso a todas las funciones premium.
          </DialogContentText>
          {error && (
            <DialogContentText color="error">
              {error}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            Cancelar
          </Button>
          <Button onClick={handleCancelSubscription} color="secondary" disabled={loading}>
            {loading ? 'Cancelando...' : 'Confirmar'}
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
}