import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom'; // Para obtener el ideaId desde la URL
import { 
  Typography, Paper, Box, CircularProgress, 
  Container, IconButton, Divider, Chip, Tooltip,
  Alert, Snackbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BookmarkIcon from '@mui/icons-material/Bookmark';

export default function SavedIdeas({ user }) {
  const { ideaId } = useParams(); // Obtiene el ideaId desde la URL
  const [savedIdeas, setSavedIdeas] = useState([]); // Mantiene la lista completa de ideas
  const [savedIdea, setSavedIdea] = useState(null); // Para manejar una única idea si se pasa un ideaId
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchSavedIdeas = useCallback(async () => {
    try {
      const url = ideaId 
        ? `${process.env.REACT_APP_API_URL}/api/saved-ideas/${user.id}/${ideaId}` // Si hay ideaId, carga una idea específica
        : `${process.env.REACT_APP_API_URL}/api/saved-ideas/${user.id}`; // Si no hay ideaId, carga todas las ideas

      const response = await fetch(url);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Error fetching saved ideas');
      } else {
        const data = await response.json();
        if (ideaId) {
          setSavedIdea(data); // Guarda la idea específica
        } else {
          setSavedIdeas(data); // Guarda la lista de ideas
        }
      }
    } catch (error) {
      console.error("Error fetching saved ideas:", error);
      setError(error instanceof Error ? error.message : "No se pudieron cargar las ideas guardadas");
    } finally {
      setLoading(false);
    }
  }, [user.id, ideaId]);

  useEffect(() => {
    if (user?.id) {
      fetchSavedIdeas();
    }
  }, [fetchSavedIdeas, user]);

  const handleDeleteIdea = async (ideaIdToDelete) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/delete-idea/${ideaIdToDelete}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        const errorMsg = await response.text();
        throw new Error(errorMsg || 'Error deleting idea');
      }
      if (ideaId) {
        setSavedIdea(null); // Si se está viendo una idea específica, limpiamos la pantalla
      } else {
        setSavedIdeas(savedIdeas.filter(idea => idea._id !== ideaIdToDelete)); // Filtra la lista de ideas guardadas
      }
      setSnackbar({ open: true, message: 'Idea eliminada con éxito', severity: 'success' });
    } catch (error) {
      console.error("Error deleting idea:", error);
      setSnackbar({ open: true, message: 'No se pudo eliminar la idea', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (ideaId && savedIdea) {
    // Si se está viendo una idea específica
    return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <BookmarkIcon sx={{ mr: 1 }} /> {savedIdea.titulo}
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
        <Paper elevation={3} sx={{ mb: 3, p: 3, borderRadius: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{savedIdea.titulo}</Typography>
            <Tooltip title="Eliminar idea">
              <IconButton onClick={() => handleDeleteIdea(savedIdea._id)} color="error">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>{savedIdea.guion}</Typography>
          <Box sx={{ mt: 2 }}>
            {savedIdea.hashtags.map((tag, index) => (
              <Chip key={index} label={tag} sx={{ mr: 1, mb: 1 }} />
            ))}
          </Box>
        </Paper>
        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <BookmarkIcon sx={{ mr: 1 }} /> Ideas Guardadas
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}

      {savedIdeas.length === 0 ? (
        <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
          <Typography>No tienes ideas guardadas aún.</Typography>
        </Paper>
      ) : (
        savedIdeas.map((idea) => (
          <Paper key={idea._id} elevation={3} sx={{ mb: 3, p: 3, borderRadius: 2 }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{idea.titulo}</Typography>
              <Tooltip title="Eliminar idea">
                <IconButton onClick={() => handleDeleteIdea(idea._id)} color="error">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body1" sx={{ mb: 2, whiteSpace: 'pre-wrap' }}>{idea.guion}</Typography>
            <Box sx={{ mt: 2 }}>
              {idea.hashtags.map((tag, index) => (
                <Chip key={index} label={tag} sx={{ mr: 1, mb: 1 }} />
              ))}
            </Box>
          </Paper>
        ))
      )}

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}