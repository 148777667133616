import React, { useState, useEffect, useCallback } from 'react'; 
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Typography, Button, Box, CircularProgress, 
  Snackbar, Container, AppBar, Toolbar, IconButton,
  List, ListItem, ListItemText, Divider, Paper, Alert
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { fetchVideoDetails, fetchComments } from '../services/YouTubeServices';
import { generarIdea } from '../services/ChatGPTServices';
import { useTheme } from '@mui/material/styles';

export default function VideoDetails({ user, isPremium, onUpgradeToPremium, checkUserPremiumStatus }) {
  const { videoId } = useParams();
  const navigate = useNavigate();
  const [videoDetails, setVideoDetails] = useState(null);
  const [comments, setComments] = useState([]);
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingIdea, setLoadingIdea] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "info" });
  const [credits, setCredits] = useState(isPremium ? 'unlimited' : 5);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [savedIdeas, setSavedIdeas] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [loadingSavedIdeas, setLoadingSavedIdeas] = useState(false);
  const theme = useTheme();

  const showMessage = useCallback((message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const loadVideoDetails = useCallback(async () => {
    if (!videoId) return;
    try {
      const details = await fetchVideoDetails(videoId);
      
      // Valores predeterminados para videoDetails
      const defaultDetails = {
        title: "Título no disponible",
        thumbnail: "",
        author: "Autor no disponible",
        channelId: "",
        likeCount: 0,
        viewCount: 0,
        ...details, // Sobrescribir con valores reales si están presentes
      };
  
      setVideoDetails(defaultDetails);
  
      const fetchedComments = await fetchComments(videoId);
      setComments(fetchedComments.slice(0, 10));
    } catch (error) {
      console.error("Error loading video details:", error);
      showMessage("Error al cargar los detalles del video.", "error");
    } finally {
      setLoading(false);
    }
  }, [videoId, showMessage]);  

  const fetchSavedIdeas = useCallback(async () => {
    if (user?.id && isPremium) {
      setLoadingSavedIdeas(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/saved-ideas/${user.id}`, {
          credentials: 'include',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        });
        if (response.ok) {
          const data = await response.json();
          setSavedIdeas(data);
        }
      } finally {
        setLoadingSavedIdeas(false);
      }
    }
  }, [user, isPremium]);

  useEffect(() => {
    loadVideoDetails();
    fetchSavedIdeas();
  }, [loadVideoDetails, fetchSavedIdeas]);

  const handleGenerateIdea = async () => {
    if (!user) {
      showMessage("Debes iniciar sesión para generar ideas.", "warning");
      return;
    }
    if (!videoDetails) return;

    const checkCreditsResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/check-credits`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userId: user.id }),
    });

    const creditsData = await checkCreditsResponse.json();
    if (!creditsData.canGenerate && !isPremium) {
      showMessage("No tienes créditos suficientes, Actualiza a Premium para acceder a ideas Ilimitadas.", "info");
      return;
    }

    setLoadingIdea(true);
    setRetryAttempts(0);
    setIsSaved(false);

    const attemptGeneration = async () => {
      try {
        const result = await generarIdea(videoDetails, comments, isPremium);
        if (result.success && result.idea) {
          setIdea(result.idea);
          if (!isPremium) {
            setCredits(creditsData.credits);
          }
        } else {
          throw new Error(result.error || "Error desconocido al generar la idea.");
        }
      } catch (error) {
        console.error("Error generating idea:", error);
        if (retryAttempts < 3) {
          setRetryAttempts(prev => prev + 1);
          await attemptGeneration();
        } else {
          showMessage("No se pudo generar una idea. Por favor, intenta de nuevo más tarde.", "error");
        }
      } finally {
        setLoadingIdea(false);
      }
    };

    await attemptGeneration();
  };

  const handleSaveIdea = async () => {
    if (!isPremium) {
      showMessage("Esta función es solo para usuarios premium", "warning");
      return;
    }

    if (idea && user) {
      const newIdea = {
        userId: user.id,
        videoId: videoId,
        titulo: idea.titulo,
        guion: idea.guion,
        hashtags: idea.hashtags
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/save-idea`, {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(newIdea),
          credentials: 'include'
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
        }

        const savedIdea = await response.json();
        setSavedIdeas(prevIdeas => [...prevIdeas, savedIdea.savedIdea]);
        setIsSaved(true);
        showMessage("Idea guardada exitosamente", "success");
        fetchSavedIdeas();
      } catch (error) {
        console.error("Error saving idea:", error);
        showMessage(`Error al guardar la idea: ${error.message}. Por favor, intenta de nuevo.`, "error");
      }
    }
  };

  const handleCopyIdea = (ideaToCopy) => {
    if (ideaToCopy) {
      const ideaText = `
Título: ${ideaToCopy.titulo}

Guión:
${ideaToCopy.guion}

Hashtags: ${ideaToCopy.hashtags.join(' ')}
      `.trim();

      navigator.clipboard.writeText(ideaText).then(() => {
        showMessage("Idea copiada al portapapeles", "success");
      }, (err) => {
        console.error('Error al copiar la idea: ', err);
        showMessage("Error al copiar la idea", "error");
      });
    }
  };

  const formatCount = (count) => {
    if (count === undefined || count === null) return "0";
    if (count >= 1000000000) return (count / 1000000000).toFixed(1) + 'B';
    if (count >= 1000000) return (count / 1000000).toFixed(1) + 'M';
    if (count >= 1000) return (count / 1000).toFixed(1) + 'K';
    return count.toString();
  };  

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden' }}>
      <AppBar position="static" color="default" elevation={0}>
        <Toolbar style={{ minHeight: '48px', padding: 0 }}>
          <IconButton 
            onClick={() => navigate(-1)} 
            aria-label="back" 
            sx={{ 
              width: '48px', 
              height: '48px', 
              borderRadius: 0, 
              bgcolor: 'transparent',
              '&:hover': {
                bgcolor: 'transparent'
              }
            }}>
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
        <Container maxWidth="md">
          {videoDetails && (
            <>
              <Box sx={{ position: 'relative', mb: 2 }}>
                <img src={videoDetails.thumbnail} alt={videoDetails.title} style={{ width: '100%', maxHeight: 450, objectFit: 'cover' }} />
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<PlayArrowIcon />}
                  sx={{ position: 'absolute', bottom: 7, right: 0, bgcolor: 'rgba(0,0,0,0.6)', color: 'white' }}
                  onClick={() => window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank')}
                >
                  Ver
                </Button>
                <Box sx={{ position: 'absolute', bottom: 7, left: 0, bgcolor: 'rgba(0,0,0,0.6)', p: 1, borderRadius: 0 }}>
                  <Typography variant="body2" sx={{ color: 'white' }}>
                    {formatCount(videoDetails.viewCount)} vistas
                  </Typography>
                </Box>
              </Box>
              <Typography variant="h5" align="center" sx={{ mb: 1 }}>
                {videoDetails.title}
              </Typography>
              <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" mb={2}>
                <Typography variant="subtitle1" sx={{ mr: 1 }}>
                  Creador:
                </Typography>
                <Typography variant="subtitle1" sx={{ cursor: 'pointer', textDecoration: 'underline', color:theme.palette.mode === 'dark' ? 'white' : 'black',whiteSpace: 'nowrap' }}
                  onClick={() => window.open(`https://www.youtube.com/channel/${videoDetails.channelId}`, '_blank')}>
                  {videoDetails.author}
                </Typography>
                <Box display="flex" alignItems="center" ml={2} sx={{ whiteSpace: 'nowrap' }}>
                  <ThumbUpIcon sx={{ fontSize: 20, mr: 0.5 }} />
                  <Typography variant="subtitle2">
                    {formatCount(videoDetails.likeCount)}
                  </Typography>
                </Box>
              </Box>
              {user ? (
                <Box display="flex" justifyContent="center" mb={2}>
                  <Button
                    variant="contained"
                    onClick={handleGenerateIdea}
                    disabled={loadingIdea || (!isPremium && credits <= 0)}
                    sx={{ maxWidth: '300px', width: '100%' }}
                  >
                    {loadingIdea
                      ? `Generando Idea${retryAttempts > 0 ? ` (Intento ${retryAttempts}/3)` : ''}...`
                      : !isPremium && credits <= 0
                        ? 'Sin Créditos'
                        : idea
                          ? 'Generar Otra Idea'
                          : 'Generar Idea Para Video'}
                  </Button>
                </Box>
              ) : (
                <Box textAlign="center" mt={2}>
                  <Typography variant="body1" gutterBottom>
                    Debes iniciar sesión para generar ideas.
                  </Typography>
                  <Button variant="contained" color="primary" onClick={() => navigate('/login')}>
                    Iniciar Sesión
                  </Button>
                </Box>
              )}
              {user && idea && (
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom>
                    {idea.titulo}
                  </Typography>
                  <Typography variant="body2" paragraph sx={{ whiteSpace: 'pre-wrap' }}>
                    {idea.guion}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    Hashtags: {idea.hashtags.join(' ')}
                  </Typography>
                  <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                    <Button
                      variant="outlined"
                      onClick={handleSaveIdea}
                      startIcon={isSaved ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                    >
                      {isSaved ? 'Idea Guardada' : 'Guardar Idea'}
                    </Button>
                    <Button variant="outlined" onClick={() => handleCopyIdea(idea)} startIcon={<ContentCopyIcon />}>
                      Copiar Idea
                    </Button>
                  </Box>
                </Box>
              )}
              {isPremium && (
                <Box mt={4}>
                  <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <BookmarkIcon sx={{ mr: 1 }} /> Ideas Guardadas
                  </Typography>
                  {loadingSavedIdeas ? (
                    <CircularProgress />
                  ) : savedIdeas.length > 0 ? (
                    <Paper elevation={3} sx={{ p: 2 }}>
                      <List>
                        {savedIdeas.map((savedIdea, index) => (
                          <React.Fragment key={savedIdea._id}>
                            <ListItem
                              button
                              onClick={() => navigate('/saved-ideas')}
                              alignItems="flex-start"
                            >
                              <ListItemText primary={`${index + 1}. ${savedIdea.titulo}`} />
                            </ListItem>
                            {index < savedIdeas.length - 1 && <Divider />}
                          </React.Fragment>
                        ))}
                      </List>
                    </Paper>
                  ) : (
                    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
                      <Typography>No tienes ideas guardadas aún.</Typography>
                    </Paper>
                  )}
                </Box>
              )}
            </>
          )}
        </Container>
      </Box>
      {!isPremium ? (
        <Typography variant="body2" align="center" sx={{ py: 1 }}>
          Créditos restantes: {credits}
        </Typography>
      ) : (
        <Typography variant="body2" align="center" sx={{ py: 1 }}>
          Créditos restantes: Ilimitados
        </Typography>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}