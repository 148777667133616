import React, { useState } from 'react';
import {
  AppBar, Toolbar, Typography, Button, IconButton, Avatar,
  useMediaQuery, useTheme, Menu, MenuItem, ListItemIcon, ListItemText, Box, Dialog
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  AccountCircle, ExitToApp, Menu as MenuIcon,
  Brightness4, Brightness7, Close as CloseIcon, Bookmark as BookmarkIcon, Star as StarIcon, Cancel as CancelIcon
} from '@mui/icons-material';
import logoLight from '../assets/logo.png'; // Logo claro para modo nocturno
import logoDark from '../assets/logo-dark.png';  // Logo oscuro para modo claro
import PremiumSubscription from './PremiumSubscription'; // Importar componente de suscripción
import CancelSubscription from './CancelSubscription';  // Importar componente de cancelación

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#ff6666',
}));

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 16px',
});

const LogoContainer = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: 'inherit',
  marginRight: 'auto',
});

const Logo = styled('img')({
  height: '50px',
  marginRight: '1',
});

const BrandText = styled(Typography)({
  fontWeight: 'bold',
  marginLeft: '-5px',
  fontSize: '1.5rem',
});

const SettingsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const UserInfo = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
});

export default function Header({ darkMode, toggleDarkMode, user, onLogout, isPremium }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPremiumDialog, setOpenPremiumDialog] = useState(false);
  const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] = useState(false); // Estado para el componente CancelSubscription

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPremiumDialog = () => {
    setOpenPremiumDialog(true);
  };

  const handleClosePremiumDialog = () => {
    setOpenPremiumDialog(false);
  };

  const handleOpenCancelSubscriptionDialog = () => {
    setOpenCancelSubscriptionDialog(true);
  };

  const handleCloseCancelSubscriptionDialog = () => {
    setOpenCancelSubscriptionDialog(false);
  };

  const initials = user ? user.name.split(' ').map(n => n[0]).join('').toUpperCase() : '';

  return (
    <>
      <StyledAppBar position="static">
        <StyledToolbar>
          <LogoContainer to="/">
            <Logo src={darkMode ? logoLight : logoDark} alt="TrendTube AI Logo" />
            <BrandText variant="h5" component="div">
              TrendTube AI
            </BrandText>
          </LogoContainer>
          <SettingsContainer>
            {isMobile ? (
              <>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenu}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {user && (
                    <MenuItem onClick={handleClose}>
                      <Avatar sx={{ mr: 2 }}>{initials}</Avatar>
                      <ListItemText primary={user.name} secondary={isPremium ? '(PRO)' : '(FREE)'} />
                    </MenuItem>
                  )}
                  {isPremium && (
                    <>
                      <MenuItem component={Link} to="/saved-ideas" onClick={handleClose}>
                        <ListItemIcon>
                          <BookmarkIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Ideas Guardadas" />
                      </MenuItem>
                      <MenuItem onClick={handleOpenCancelSubscriptionDialog}>
                        <ListItemIcon>
                          <CancelIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Suscripción" />
                      </MenuItem>
                    </>
                  )}
                  <MenuItem onClick={toggleDarkMode}>
                    <ListItemIcon>
                      {darkMode ? <Brightness7 fontSize="small" /> : <Brightness4 fontSize="small" />}
                    </ListItemIcon>
                    <ListItemText primary={darkMode ? "Modo Claro" : "Modo Oscuro"} />
                  </MenuItem>
                  {!isPremium && (
                    <MenuItem onClick={handleOpenPremiumDialog}>
                      <ListItemIcon>
                        <StarIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Premium" />
                    </MenuItem>
                  )}
                  {user ? (
                    <MenuItem onClick={onLogout}>
                      <ListItemIcon>
                        <ExitToApp fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Cerrar Sesión" />
                    </MenuItem>
                  ) : (
                    <MenuItem component={Link} to="/login" onClick={handleClose}>
                      <ListItemIcon>
                        <AccountCircle fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Iniciar Sesión" />
                    </MenuItem>
                  )}
                </Menu>
              </>
            ) : (
              <>
                <UserInfo>
                  {user ? (
                    <>
                      <Avatar sx={{ width: 32, height: 32, bgcolor: 'white', color: '#ff6666', marginRight: 1, fontSize: '0.875rem', fontWeight: 'bold' }}>
                        {initials}
                      </Avatar>
                      <Typography variant="body2" sx={{ fontWeight: 'bold', marginRight: 1, color: 'white' }}>
                        {isPremium ? '(PRO)' : '(FREE)'}
                      </Typography>
                      <IconButton color="inherit" onClick={onLogout} size="small" sx={{ marginLeft: 1 }}>
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : (
                    <Button 
                      color="inherit" 
                      startIcon={<AccountCircle />} 
                      component={Link} 
                      to="/login"
                      sx={{ color: 'white', textTransform: 'none' }}
                    >
                      Iniciar sesión
                    </Button>
                  )}
                </UserInfo>
                <IconButton sx={{ ml: 1 }} onClick={toggleDarkMode} color="inherit">
                  {darkMode ? <Brightness7 /> : <Brightness4 />}
                </IconButton>
                {!isPremium && (
                  <Button 
                    color="inherit"
                    onClick={handleOpenPremiumDialog}
                    startIcon={<StarIcon />}
                    sx={{ color: 'white', textTransform: 'none' }}
                  >
                    Premium
                  </Button>
                )}
                {isPremium && (
                  <>
                    <Button 
                      color="inherit" 
                      component={Link} 
                      to="/saved-ideas"
                      startIcon={<BookmarkIcon />}
                    >
                      Ideas Guardadas
                    </Button>
                    <Button 
                      color="inherit"
                      onClick={handleOpenCancelSubscriptionDialog}
                      startIcon={<CancelIcon />}
                      sx={{ color: 'white', textTransform: 'none' }}
                    >
                      Suscripción
                    </Button>
                  </>
                )}
              </>
            )}
          </SettingsContainer>
        </StyledToolbar>
      </StyledAppBar>

      {/* Diálogo para Suscripción Premium */}
      <Dialog open={openPremiumDialog} onClose={handleClosePremiumDialog}>
        <PremiumSubscription
          onUpgrade={() => {
            handleClosePremiumDialog();
            window.location.reload();
          }}
          onClose={handleClosePremiumDialog}
          user={user}
        />
      </Dialog>

      {/* Diálogo para Cancelar Suscripción */}
      <Dialog open={openCancelSubscriptionDialog} onClose={handleCloseCancelSubscriptionDialog}>
        <CancelSubscription onClose={handleCloseCancelSubscriptionDialog} user={user} />
      </Dialog>
    </>
  );
}